import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import Checkbox from '../../../../../../components/Checkbox';
import FinalForm from '../../../../../../components/FinalForm';
import Modal from '../../../../../../components/Modal';
import { H2, Paragraph } from '../../../../../../components/Typography';
import isEmptyObject from '../../../../../../helpers/isEmptyObject';
import { ListingContext } from '../../../../../../providers/ListingProvider';

import DailyFeesSubform from './components/DailyFeesSubform';
import LateFeeDigest from './components/LateFeeDigest';
import LateFeeSupportLink from './components/LateFeeSupportLink';
import OneTimeFeeSubform from './components/OneTimeFeeSubform';
import { LateFeeLimitTypeEnum, LateFeeUnitEnum } from './constants';

import styles from './ConfigureLateFeesModal.module.scss';

const initialState = {
  oneTimeFeeAmount: null,
  oneTimeFeeGracePeriod: 1,
  oneTimeFeeApplied: false,
  dailyFeesApplied: false,
  dailyLateFeeType: LateFeeUnitEnum.FLAT,
  oneTimeLateFeeType: LateFeeUnitEnum.FLAT,
  lateFeeLimitType: LateFeeLimitTypeEnum.NO_LIMIT,
};

const ConfigureLateFeesModal = ({ open, handleSubmit, onClose, data }) => {
  const listing = useContext(ListingContext);
  const state = listing?.state;
  const city = listing?.city;

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="large"
      showCloseIcon
      disableOutsideClick
    >
      <FinalForm
        className={'mt-0'}
        initialValues={isEmptyObject(data) ? initialState : data}
        onSubmit={handleSubmit}
      >
        {() => (
          <>
            <Modal.Content noHeader>
              <H2>Configure automatic late fees</H2>
              <Paragraph className={styles.paragraph} size="large">
                Need help setting a late fee policy? Refer to our{' '}
                <LateFeeSupportLink city={city} state={state} /> for guidance
                based on State, City, or County laws.
              </Paragraph>

              <Field
                component={Checkbox}
                type="checkbox"
                id="oneTimeFeeApplied"
                name="oneTimeFeeApplied"
                label="One-time initial fee"
                className={styles.feeAppliedCheckbox}
              />
              <OneTimeFeeSubform />

              <Field
                component={Checkbox}
                type="checkbox"
                id="dailyFeesApplied"
                name="dailyFeesApplied"
                label="Daily late fees"
                className={styles.feeAppliedCheckbox}
              />
              <DailyFeesSubform />
            </Modal.Content>

            <Modal.Footer layout="col">
              <div className={styles.btnContainer}>
                <Button secondary onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </div>

              <LateFeeDigest insideModal />
            </Modal.Footer>
          </>
        )}
      </FinalForm>
    </Modal>
  );
};

ConfigureLateFeesModal.propTypes = {
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object, // TODO maybe type?
};

export default ConfigureLateFeesModal;
