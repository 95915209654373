import Decimal from 'jsdecimal';

export const isLowerOrEqualTo = (n, customError) => (value) => {
  const valueInCents = Decimal(value).mul(100).toFloat();
  if (valueInCents && valueInCents > n) {
    if (customError) return customError;
    return 'Amount entered cannot be greater than the amount due';
  }
  return undefined;
};

export default isLowerOrEqualTo;
