import { isAdminLogin } from '../../core/auth/authService';
import { getSignupTrackingCookie } from '../../helpers/signupTrackingData';
import { getConfig } from '../configService';

export function segmentTracking(event, properties) {
  const loggedInAsAdmin = isAdminLogin();
  if (!window.analytics) {
    return;
  }
  if (!loggedInAsAdmin) {
    //grab values from cookie and send them to segment
    const { source, medium, campaign, content, term } =
      getSignupTrackingCookie();
    window.analytics.track(event, properties, {
      context: {
        campaign: {
          source,
          medium,
          campaign,
          content,
          term,
          name: campaign,
        },
      },
    });
  }
}

export function segmentIdentify(globalId, intercomHash, traits = {}) {
  const { IS_OWNER } = getConfig();
  const loggedInAsAdmin = isAdminLogin();
  if (window.analytics && !loggedInAsAdmin) {
    const decodedId = atob(globalId).split(':').pop();
    window.analytics.ready(function () {
      window.analytics.identify(
        `${IS_OWNER ? 'Owner' : 'Renter'}:${decodedId}`,
        { ...traits },
        {
          ...(intercomHash && {
            integrations: {
              Intercom: {
                user_hash: intercomHash,
              },
            },
          }),
        },
        () => {
          //grab values from cookie and send them to segment
          const { source, medium, campaign, content, term } =
            getSignupTrackingCookie();
          window.analytics.page(
            null,
            null,
            {},
            {
              context: {
                campaign: {
                  source,
                  medium,
                  campaign,
                  content,
                  term,
                  name: campaign,
                },
              },
            },
          );
        },
      );
    });
  }
}
