import React, { useEffect, useMemo } from 'react';
import { Field, useField } from 'react-final-form';

import Divider from '../../../../../../../components/Divider';
import InputWithIcon from '../../../../../../../components/InputWithIcon';
import RadioButton from '../../../../../../../components/RadioButton';
import SegmentedController from '../../../../../../../components/SegmentedController';
import SelectField from '../../../../../../../components/SelectField';
import { Paragraph } from '../../../../../../../components/Typography';
import parseToPositiveNumbers from '../../../../../../../helpers/parseToPositiveNumbers';
import DollarIcon from '../../../../../../../icons/Dollar';
import PercentageIcon from '../../../../../../../icons/Percentage';
import composeValidators from '../../../../../../../validators/composeValidators';
import fieldRequired from '../../../../../../../validators/fieldRequired';
import isGreaterThan from '../../../../../../../validators/isGreaterThan';
import isLowerOrEqualTo from '../../../../../../../validators/isLowerOrEqualTo';
import isNumber from '../../../../../../../validators/isNumber';
import {
  LateFeeLimitTypeEnum,
  lateFeeOptions,
  LateFeeUnitEnum,
} from '../constants';
import {
  getGracePeriodDays,
  getGracePeriodOptions,
} from '../gracePeriodOptions';

import styles from '../ConfigureLateFeesModal.module.scss';

const greaterThanOneTimeGracePeriod = (value, formValues) => {
  if (formValues.oneTimeFeeApplied) {
    return isGreaterThan(formValues.oneTimeFeeGracePeriod)(value);
  }
  return null;
};

const DailyFeesSubform = () => {
  const {
    input: { value: oneTimeFeeApplied },
  } = useField('oneTimeFeeApplied');
  const {
    input: { value: dailyFeesApplied },
  } = useField('dailyFeesApplied');
  const {
    input: { value: lateFeeLimitUnit },
  } = useField('lateFeeLimitUnit');
  const {
    input: { value: lateFeeLimitType },
  } = useField('lateFeeLimitType');
  const {
    input: { value: oneTimeFeeGracePeriod },
  } = useField('oneTimeFeeGracePeriod');
  const {
    input: {
      value: dailyLateFeeGracePeriod,
      onChange: setDailyLateFeeGracePeriod,
    },
  } = useField('dailyLateFeeGracePeriod');

  const startingOptions = useMemo(
    () =>
      getGracePeriodOptions(
        oneTimeFeeApplied ? Number(oneTimeFeeGracePeriod) : 0,
      ),
    [oneTimeFeeGracePeriod, oneTimeFeeApplied],
  );

  useEffect(() => {
    if (
      oneTimeFeeApplied &&
      Number(dailyLateFeeGracePeriod) <= Number(oneTimeFeeGracePeriod)
    ) {
      setDailyLateFeeGracePeriod(Number(oneTimeFeeGracePeriod) + 1);
    }
  }, [
    oneTimeFeeGracePeriod,
    oneTimeFeeApplied,
    dailyLateFeeGracePeriod,
    setDailyLateFeeGracePeriod,
  ]);

  if (!dailyFeesApplied) return null;

  const lateFeeLimitUnitIcon =
    lateFeeLimitUnit === LateFeeUnitEnum.FLAT ? DollarIcon : PercentageIcon;

  const validateLimitAmount = (value, formValues) => {
    if (
      formValues.lateFeeLimitType === LateFeeLimitTypeEnum.MAX_TOTAL_FEE_AMOUNT
    ) {
      if (formValues.lateFeeLimitUnit === LateFeeUnitEnum.PERCENTAGE)
        return isLowerOrEqualTo(10000, 'Cannot exceed 100% of rent')(value);
      if (formValues.lateFeeLimitUnit === LateFeeUnitEnum.FLAT)
        return isGreaterThan(0)(value);
    }

    return null;
  };

  return (
    <>
      <div className={styles.dailyFeeContainer}>
        <Field
          component={InputWithIcon}
          id="dailyLateFeeAmount"
          name="dailyLateFeeAmount"
          className={styles.miniField}
          label="Amount"
          type="number"
          step=".01"
          pattern="[0-9]*"
          parse={parseToPositiveNumbers}
          inputMode="decimal"
          validate={composeValidators(
            isNumber,
            fieldRequired,
            isGreaterThan(0),
          )}
          icon={DollarIcon}
        />
        <Field
          label="Starting"
          component={SelectField}
          id="dailyLateFeeGracePeriod"
          name="dailyLateFeeGracePeriod"
          options={startingOptions}
          className={styles.gracePeriod}
          validate={composeValidators(
            fieldRequired,
            greaterThanOneTimeGracePeriod,
          )}
        />
      </div>
      <Divider text={''} className={styles.divider} />
      <div>
        <Paragraph size="large" weight="semi">
          Late fee limit
        </Paragraph>
        <div className={styles.radioContainer}>
          <Field
            name="lateFeeLimitType"
            component={RadioButton}
            type="radio"
            value={LateFeeLimitTypeEnum.NO_LIMIT}
            label="No limit"
            className={styles.radioFitContent}
          />

          <Field
            name="lateFeeLimitType"
            component={RadioButton}
            type="radio"
            value={LateFeeLimitTypeEnum.MAX_DAYS}
            className={styles.radioFitContent}
            label={
              <div className={styles.radioDays}>
                Stop daily fees after{' '}
                <Field
                  component={SelectField}
                  id="lateFeeLimitDays"
                  name="lateFeeLimitDays"
                  className={styles.selectField}
                  options={getGracePeriodDays()}
                  disabled={lateFeeLimitType !== LateFeeLimitTypeEnum.MAX_DAYS}
                />{' '}
                days
              </div>
            }
          />
          <div className={styles.totalLateFeesContainer}>
            <Field
              name="lateFeeLimitType"
              component={RadioButton}
              type="radio"
              value={LateFeeLimitTypeEnum.MAX_TOTAL_FEE_AMOUNT}
              label="Total late fees should not exceed"
              className={styles.radioLabelContainer}
            />

            <div className={styles.radioFields}>
              <Field
                id="lateFeeLimitUnit"
                name="lateFeeLimitUnit"
                component={SegmentedController}
                initialValue={lateFeeLimitUnit || LateFeeUnitEnum.FLAT}
                options={lateFeeOptions}
                validateFields={['lateFeeLimitAmount']}
                containerClassName={styles.radioLabelTabs}
                disabled={
                  lateFeeLimitType !== LateFeeLimitTypeEnum.MAX_TOTAL_FEE_AMOUNT
                }
              />
              <Field
                component={InputWithIcon}
                id="lateFeeLimitAmount"
                name="lateFeeLimitAmount"
                className={styles.totalField}
                type="number"
                step=".01"
                pattern="[0-9]*"
                parse={parseToPositiveNumbers}
                inputMode="decimal"
                disabled={
                  lateFeeLimitType !== LateFeeLimitTypeEnum.MAX_TOTAL_FEE_AMOUNT
                }
                validate={composeValidators(isNumber, validateLimitAmount)}
                icon={lateFeeLimitUnitIcon}
                iconRight={lateFeeLimitUnit === LateFeeUnitEnum.PERCENTAGE}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DailyFeesSubform;
