import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { REIHUB_DISCOUNT_FACTOR } from '../../components/ReiHubSubscription/constants';
import { useUserFields } from '../../core/TTgraphql';
import createReiHubSubscription from '../../graphql/mutations/rei_hub/createReiHubSubscription.graphql';
import calculateReiHubPrice from '../../helpers/calculate-reihub-price';
import { createAndSaveIdempotencyKey } from '../../helpers/createAndSaveIdempotencyKey';
import getReiHubPrice from '../../helpers/getReiHubPrice';
import { resetIdempotencyKey } from '../../helpers/resetIdempotencyKey';
import StripeElementsModal from '../../pages/common/stripe/StripeElementsModal';
import { useErrorToast } from '../Toast';

const ReiHubModalWrapped = ({
  onClose,
  onSuccess,
  refetchQueries,
  unitCount,
}) => {
  const errorToast = useErrorToast();

  const { user } = useUserFields([
    'reihub_listings_count',
    'reihub_yearly_pricings',
  ]);

  const { reihub_listings_count: listings_count, id: userId } = user;

  const reiHubYearlyPrice = unitCount
    ? getReiHubPrice(user?.reihub_yearly_pricings, unitCount)
    : null;

  const monthlyReiHubPrice =
    calculateReiHubPrice(listings_count) * REIHUB_DISCOUNT_FACTOR;

  const [createSubscription] = useMutation(createReiHubSubscription, {
    refetchQueries: refetchQueries?.length
      ? refetchQueries.map((query) => ({ query }))
      : null,
    awaitRefetchQueries: true,
  });

  const onToken = useCallback(async (token) => {
    let error;
    try {
      const idempotencyKey = createAndSaveIdempotencyKey();
      await createSubscription({
        variables: {
          token,
          idempotencyKey,
          unitCount,
        },
      });

      onSuccess();
    } catch (e) {
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
      errorToast(message);
      error = e?.graphQLErrors;
    }

    resetIdempotencyKey(error);
  });

  if (!userId) {
    return null;
  }

  return (
    <StripeElementsModal
      open
      onClose={() => {
        onClose();
      }}
      onToken={onToken}
      slashedAmount={reiHubYearlyPrice?.slashedPrice}
      title="Subscribe to REI Hub"
      amount={reiHubYearlyPrice ? reiHubYearlyPrice.price : monthlyReiHubPrice}
      buttonLabel={unitCount ? `Pay $${reiHubYearlyPrice.price}` : 'SUBSCRIBE'}
      amountLabel={unitCount ? 'year' : 'month'}
      onPayClicked={() => {}}
    />
  );
};

ReiHubModalWrapped.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.array,
  openingLocation: PropTypes.string,
  unitCount: PropTypes.number,
};

export default ReiHubModalWrapped;
