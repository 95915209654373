export default function parseToPositiveIntegers(value) {
  if (!value) return '';

  // Remove all non-digit and non-dot characters and any misplaced dots
  value = value.replace(/[^0-9.]/g, '');

  // Remove multiple dots, keep only the first one
  value = value.replace(/(\..*?)\..*/g, '$1');

  // Remove leading zeros, but leave a single zero if it's followed by a dot
  value = value.replace(/^0+(?=\d)/, '');

  return value;
}
