import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import FlatButton from '../../FlatButton';

import styles from './ReiHubOfferModalFooter.module.scss';

const ReiHubOfferModalFooter = ({
  onClaimDiscount,
  onNoInterested,
  skipBtnLabel,
}) => {
  return (
    <div className={styles.offerFooter}>
      <Button onClick={onClaimDiscount} className={styles.btn}>
        Claim my discount
      </Button>
      <FlatButton onClick={onNoInterested} data-qa="not-interested-btn">
        {skipBtnLabel}
      </FlatButton>
    </div>
  );
};

ReiHubOfferModalFooter.defaultProps = {
  skipBtnLabel: 'Not right now',
};

ReiHubOfferModalFooter.propTypes = {
  onClaimDiscount: PropTypes.func.isRequired,
  onNoInterested: PropTypes.func.isRequired,
  skipBtnLabel: PropTypes.string,
};

export default ReiHubOfferModalFooter;
