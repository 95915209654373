import React from 'react';
import PropTypes from 'prop-types';

import LateFeeSupportArticleLink from '../../../../../../../components/MonthlyChargeForm/LateFeeSupportArticleLink';
import { Link } from '../../../../../../../components/Typography';

const LateFeeSupportLink = ({ city, state }) => {
  if (!state) {
    return (
      <Link href="https://support.turbotenant.com/">
        support@turbotenant.com
      </Link>
    );
  }
  return (
    <LateFeeSupportArticleLink city={city} state={state}>
      support article
    </LateFeeSupportArticleLink>
  );
};

LateFeeSupportLink.propTypes = {
  state: PropTypes.string.isRequired,
  city: PropTypes.string,
};

export default LateFeeSupportLink;
