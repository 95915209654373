export const getGracePeriodDays = (startingFrom = 0) => {
  const days = [...Array(31 - startingFrom).keys()].map((index) => {
    const day = index + startingFrom + 1;
    return {
      value: day,
      label: `${day}`,
    };
  });

  if (startingFrom >= 31) {
    days.push({
      value: 32,
      label: '32',
    });
  }

  return days;
};

export const getGracePeriodOptions = (startingFrom = 0) =>
  getGracePeriodDays(startingFrom).map((day) => ({
    value: day.value,
    label: `${day.value} ${day.value > 1 ? 'days' : 'day'} after rent is due`,
  }));
