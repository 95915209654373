const getReiHubPrice = (
  pricings = [],
  unitCount,
  defaultQuarterlyCountForPrice = false,
) => {
  // 14 is default for quarterly and 21 is default for yearly
  const defaultCountForPrice = defaultQuarterlyCountForPrice ? 14 : 21;

  if (unitCount > defaultCountForPrice)
    return pricings.find(({ maxUnits }) => maxUnits === 'default');

  const reiHubYearlyPricing = pricings.find(
    ({ maxUnits }) => Number(maxUnits) === unitCount,
  );

  return reiHubYearlyPricing || pricings[0];
};

export default getReiHubPrice;
