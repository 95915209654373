import { partition } from 'lodash';

const composeValidators =
  (...validators) =>
  (value, formValues) => {
    const [validValidators, invalidValidators] = partition(
      validators,
      (validator) => typeof validator === 'function',
    );

    for (const validator of invalidValidators) {
      console.warn(`composeValidators: invalid validator found: ${validator}`);
    }

    return validValidators.reduce(
      (error, validator) => error || validator(value, formValues),
      undefined,
    );
  };

export default composeValidators;
