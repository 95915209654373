import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { createForm } from 'final-form';
import arrayMutators from 'final-form-arrays';

const FinalForm = (
  { id, children, onSubmit = () => {}, className, ...props },
  ref,
) => {
  const formRef = React.useRef(
    createForm({
      keepDirtyOnReinitialize: true,
      mutators: {
        ...arrayMutators,
      },
      ...props,
      onSubmit,
    }),
  );

  useEffect(() => {
    if (ref != null) ref.current = formRef.current;
  }, [formRef, ref]);

  return (
    <Form form={formRef.current}>
      {({ handleSubmit, values, ...rest }) => (
        <form id={id} onSubmit={handleSubmit} className={className}>
          {typeof children === 'function'
            ? children({ values, ...rest })
            : children}
        </form>
      )}
    </Form>
  );
};

export default React.forwardRef(FinalForm);
